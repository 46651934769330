// todo: disabled button styles
button {
  &:disabled {
    cursor: default;
  }
  &.disabled {
    cursor: default;
  }
}

// todo: temp c-dark-theme plumbing for select option
.c-dark-theme option {
  background-color: #34343b;
  border: 1px solid #23282c;
}

// todo: brand button icon margin
.btn-brand:not(:only-child) {
  .c-icon {
    margin-top: 0 !important;
  }
}

body {
  background-color: unset;
}
.btn-primary {
  color: white;
  background-color: rgb(11, 206, 187);
  border-color: unset;
}
.btn-primary:focus {
  color: white;
  background-color: rgb(11, 206, 187);
  box-shadow: 0 5px rgb(11, 206, 187);
  border-color: unset;
}
.btn-primary.dropdown-item {
  color: white;
  background-color: rgb(11, 206, 187);
  border-color: unset;
  box-shadow: unset;
  transform: translateY(4px);
}
.btn-primary.dropdown-item:focus {
  color: white;
  background-color: rgb(11, 206, 187);
  border-color: unset;
  box-shadow: unset;
  transform: translateY(4px);
}
.btn-danger {
  background-color: rgb(255, 91, 106);
  color: white;
  border-color: unset;
}
.btn-danger:hover {
  color: white;
  background-color: rgb(255, 91, 106);
  box-shadow: 0 5px rgb(255, 91, 106);
  border-color: unset;
}
.show > .btn-danger.dropdown-toggle {
  color: white;
  background-color: rgb(11, 206, 187);
  border-color: unset;
  box-shadow: unset;
  transform: translateY(4px);
}
.show > .btn-danger.dropdown-toggle:focus {
  color: white;
  background-color: rgb(11, 206, 187);
  border-color: unset;
  box-shadow: unset;
  transform: translateY(4px);
}
.btn-outlined {
  background-color: rgb(255, 91, 106);
  color: white;
  border-color: unset;
}
.btn-outlined:hover {
  color: red;
  background-color: rgb(255, 91, 106);
  box-shadow: 0 5px rgb(255, 91, 106);
  border-color: unset;
}
