:root {
  --primary: #ff5b6a;
  --secondary: #0bcebb;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url(../fonts/nunito/NunitoRegular-vmABZ.ttf) format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url(../fonts/nunito/NunitoSemibold-w1G82.ttf) format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Nunito";
  src: local("Nunito"),
    url(../fonts/nunito/NunitoBold-1GD50.ttf) format("truetype");
  font-weight: bold;
  font-style: normal;
}
body {
  margin: 0;
  font-family: "Nunito", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background: var(--primary);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  background: var(--primary);
}

.scroll-box {
  overflow-y: scroll;
  height: 200px;
  border: 1px solid #ccc;
}
